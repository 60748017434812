import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginPage } from './login/pages/login/login.page';
import { AuthGuard } from 'src/guards/auth.guard';
import { WrapperComponent } from './wrapper/wrapper.component';
import { ErrorComponent } from './shared/pages/error/error.component';
import { MyAccountComponent } from './admin/users/pages/my-account/my-account.component';
import { ResetPasswordComponent } from './admin/users/pages/reset-password/reset-password.component';
import { ResetPasswordPage } from './login/pages/reset-password/reset-password.page';

const routes: Routes = [
    {
        path: 'login',
        component: LoginPage,
    },
    {
        path: 'reset-password',
        component: ResetPasswordPage,
    },
    {
        path: '',
        component: WrapperComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'my-account',
                component: MyAccountComponent,
            },
            {
                path: 'user/reset-password/:id/:isFirst',
                component: ResetPasswordComponent,
            },
            // ERROR
            {
                path: 'error/:code',
                component: ErrorComponent,
            },
            {
                path: 'splitview',
                loadChildren: () => import('./proofreading/split-view.module').then(m => m.SplitViewModule),
            },
            {
                path: 'stats',
                loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule),
            },

            {
                path: 'easydiadem',
                loadChildren: () => import('./easydiadem/easydiadem.module').then(m => m.EasydiademModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'project',
                loadChildren: () => import('./project-creation/project.module').then(m => m.ProjectModule),
            },
            {
                path: '',
                loadChildren: () => import('./project-dashboard/project-dashboard.module').then(m => m.ProjectDashboardModule),
            },
        ]
    },
    /*END HIDE ED for cloudflow master */
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
    ],
    exports: [
        RouterModule
    ],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule { }
