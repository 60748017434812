import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { INotification } from '../models/notification.model';
import { NotifComponent } from '../shared/components/notif/notif.component';


export interface IAlertService {
    message(message: string, options?: any): void;
    success(message: string, options?: any): void;
    error(message: string, options?: any): void;
}

@Injectable({ providedIn: 'root' })
export class AlertService implements IAlertService {

    protected _defaultOptions: any = { duration: 4000 };
    protected _locked: boolean = false;

    get defaultOptions(): any { return this._defaultOptions; }


    constructor(
        protected translateService: TranslateService,
        protected snackBar: MatSnackBar
    ) { }


    /**
     * Send message alert
     * @param message 
     * @param options 
     */
    message(message: string, opt?: MatSnackBarConfig<any>) {
        opt = _.merge({ data: { success: false, error: false } }, opt);
        this.getTranslate(message, t => this.openSnackBar(t, opt));
    }


    /**
     * Send success alert
     * @param message 
     * @param options 
     */
    success(message: string, opt?: MatSnackBarConfig<any>) {
        opt = _.merge({ data: { success: true, error: false } }, opt);
        this.getTranslate(message, t => this.openSnackBar(t, opt));
    }


    /**
     * Send error alert
     * @param message 
     * @param options 
     */
    error(message: string, opt?: MatSnackBarConfig<any>) {
        opt = _.merge({ data: { success: false, error: true } }, opt);
        this.getTranslate(message, t => this.openSnackBar(t, opt));
    }


    notif(notif: INotification, opt?: MatSnackBarConfig<any>) {
        const isError = notif.type === 'error';
        opt = _.merge({ data: { success: !isError, error: isError } }, opt);
        this.getTranslate(notif.message, t => {
            notif.message = t;
            this.openNotif(notif, opt);
        });
    }


    /**
     * Translate text
     * @param text 
     * @param callback 
     */
    protected getTranslate(text: string, callback: (text: string) => void) {
        let formatedText: string = text.toUpperCase().replace(/[\'\`\"\!\?\;]+/gm, '').replace(/\s/gm, '_');

        this.translateService.get(formatedText).subscribe(translatedResult => {
            text = translatedResult !== formatedText ? translatedResult : text;
            callback(text);
        });
    }

    /**
     * Open the snack bar
     * @param message
     * @param options 
     */
    protected openSnackBar(message: string, opt: any): void {
        if (this._locked)
            return;
        opt = _.merge(this.defaultOptions, opt, { data: { message } });
        this.snackBar.openFromComponent(AlertComponent, opt);
    }

    /**
     * Open the snack bar
     * @param message
     * @param options 
     */
    protected openNotif(notif: INotification, opt?: MatSnackBarConfig<any>): void {
        if (this._locked)
            return;
        opt = _.merge({ duration: 20000 }, { horizontalPosition: 'right', verticalPosition: 'top', }, opt, { data: notif });
        this.snackBar.openFromComponent(NotifComponent, opt);
    }


    public reset() {
        this.snackBar.dismiss();
        this._locked = false;
    }
}