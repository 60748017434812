import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";

export interface ICreaFiles {
    files: IUploadedFile[];
    comment?: string;
}

export class CreaFiles extends BaseModel<ICreaFiles> implements ICreaFiles {

    files: IUploadedFile[];
    comment?: string = undefined;

    constructor(data: Partial<CreaFiles> = {}) {
        super();
        this.patch(data);
    }
}