import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";

export interface IArchiveExistingReference {
    files: IUploadedFile[];
    comment?: string;
}

export class ArchiveExistingReference extends BaseModel<IArchiveExistingReference> implements IArchiveExistingReference {

    files: IUploadedFile[];
    comment?: string = undefined;

    constructor(data: Partial<ArchiveExistingReference> = {}) {
        super();
        this.patch(data);
    }
}