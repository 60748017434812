import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { MatDialog } from "@angular/material/dialog";
import { UploadingDialogComponent } from "../shared/components/dialog/uploading-dialog/uploading-dialog.component";
import { AlertService } from "./alert.service";
import { FilePathDescriptor } from "../models/easydiadem/request.model";
import { LivecoAPIService } from "./liveco-api.service";
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';


export interface IUploadingStatus {
    uploading: BehaviorSubject<boolean>,
    progression: {
        [key: string]: any;
    };
}

export interface IUploadOptions {
    progression?: boolean;
}

export interface IUploadedFile {
    destination: string,
    encoding: string,
    fieldname: string,
    filename: string,
    mimetype: string,
    originalname: string,
    path: string,
    cf_path: string,
    size: number;
    thumbnail?: {
        path: string,
        cf_path: string,
        mimetype: string,
        filename: string,
    };
}

interface IImgSRC {
    src: string;
}

@Injectable({ providedIn: 'root' })
export class FileService {

    defaultOptions: IUploadOptions = {
        progression: true,
    };

    constructor(
        protected auth: AuthService,
        protected alert: AlertService,
        protected dialog: MatDialog,
        protected s_liveco: LivecoAPIService,
    ) { }

    /** UPLOAD TO FILE REPOSITORY */
    protected async _uploadFile(file: File): Promise<IUploadedFile> {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await fetch(
                    `${environment.api.url}/uploads`,
                    {
                        method: 'POST',
                        body: formData,
                        headers: {
                            "Authorization": `Bearer ${this.auth.accessToken}`
                        }
                    }
                );
                const result = await response.json();
                if (!_.isNil(result?.code) && result.code !== 200) {
                    throw result;
                }
                console.log('RESPONSE FILE', result);
                const data: IUploadedFile = result;
                return data;
            } catch (err: any) {
                console.error('[FILE UPLOAD]', err.message);
                throw err;
            }
        } else {
            throw new Error('[FILE UPLOAD] [no file]');
        }
    }

    /** DOWNLOAD FROM FILE REPOSITORY */
    protected async _downloadFile(fileDetails: IUploadedFile) {
        const url = `${environment.api.url}/download/${fileDetails.filename}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${this.auth.accessToken}` // Assurez-vous d'inclure le jeton d'accès correct ici
                }
            });

            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }

            // Créer un URL pour télécharger le fichier
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileDetails.originalname || 'downloadedFile';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
        } catch (err) {
            console.error('[FILE DOWNLOAD]', err);
            throw err;
        }
    }

    /** CHECK IF FILE EXISTS */
    protected async _existFile(file_details: IUploadedFile) {
        const url = `${environment.api.url}/exists/${file_details.filename}`;
        try {

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${this.auth.accessToken}` // Assurez-vous d'inclure le jeton d'accès correct ici
                }
            });

            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
            return _.assign(await response.json(), { file_details });
        } catch (err) {
            console.error('[FILE EXISTS]', err);
            throw err;
        }
    }

    public async existsFiles(file_details: IUploadedFile[]) {
        const promises = [];
        for (const file_detail of file_details) {
            promises.push(this._existFile(file_detail));
        }
        try {
            const p = await Promise.all(promises);
            return p;
        } catch (err: any) {
            throw err;
        }
    }

    public async getFileThumbnailImg(thumbnail_filename: string) {
        return await this.s_liveco.get(thumbnail_filename, '/thumbnails');
    }

    public async downloadFile(fileDetails: IUploadedFile, options: IUploadOptions = this.defaultOptions) {
        let status;
        if (options.progression) {
            status = this.handleProgression('downloading');
        }
        try {
            const p = await this._downloadFile(fileDetails);
            return p;
        } catch (err: any) {
            throw err;
        } finally {
            if (status)
                status.uploading.next(false);
        }
    }

    public downloadXlsx(buffer: string, filename: string) {
        const docname = filename + ".xlsx";

        console.log('buffer', buffer);

        const blob = this.base64ToBlob(buffer, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");

      
        
        // const blob = new Blob([buffer], {
        //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // });

        saveAs(blob, docname);
    }

    public base64ToBlob(base64: string, mimeType: string): Blob {
        // Décoder la chaîne Base64 en tableau binaire
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
    
        // Créer un Blob à partir du tableau binaire
        return new Blob([byteArray], { type: mimeType });
    }

    public async downloadFiles(filesDetails: IUploadedFile[], options: IUploadOptions = this.defaultOptions) {
        let status;
        if (options.progression) {
            status = this.handleProgression('downloading');
        }
        const promises = [];
        for (const oneFileDetails of filesDetails) {
            promises.push(this.downloadFile(oneFileDetails));
        }
        try {
            const p = await Promise.all(promises);
            return p;
        } catch (err: any) {
            throw err;
        } finally {
            if (status)
                status.uploading.next(false);
        }
    }

    public async uploadFile(file: File, options: IUploadOptions = this.defaultOptions): Promise<IUploadedFile> {
        let status;
        if (options.progression) {
            status = this.handleProgression('uploading');
        }
        try {
            const p = await this._uploadFile(file);
            return p;
        } catch (err: any) {
            throw err;
        } finally {
            if (status)
                status.uploading.next(false);
        }
    }

    public async uploadFiles(files: File[], options: IUploadOptions = this.defaultOptions) {
        let status;
        if (options.progression) {
            status = this.handleProgression('uploading');
        }
        const promises = [];
        for (const oneFile of files) {
            promises.push(this.uploadFile(oneFile));
        }
        try {
            const p = await Promise.all(promises);
            return p;
        } catch (err: any) {
            throw err;
        } finally {
            if (status)
                status.uploading.next(false);
        }

    }

    /**
     * Take a data (generally issued by a form), retrieve the file, upload them and return the given IUploaded file to the corresponding destination in object using a FilePathDescriptor 
     * @param data 
     * @returns 
     */
    public async manageFiles(data: any, file_path: FilePathDescriptor, options: IUploadOptions = this.defaultOptions) {
        let status;
        if (options?.progression) {
            status = this.handleProgression('uploading');
        }
        const files = [];
        try {
            for (let file_desc of file_path) {
                const filesList = _.get(data, file_desc.key);
                if (filesList instanceof File) {
                    // UPLOAD FILE PLAN
                    files.push({ ...file_desc, promise: this.uploadFile(filesList) });
                } if (filesList && _.isArray(filesList) && filesList.every(f => f instanceof File)) {
                    files.push({ ...file_desc, promise: this.uploadFiles(filesList) });
                }
            }
            const result = await Promise.all(files.map(async f => f.promise));
            for (let i = 0; i < files.length; i++) {
                if (result[i]) {
                    _.set(data, files[i].key, result[i]);
                }
            }
        } catch (err: any) {
            this.alert.error(err.message);
            throw err;
        } finally {
            if (status)
                status.uploading.next(false);
        }
        // this.alert.success('ED.FILES.UPLOAD_SUCCESS')
        return data;
    }


    public handleProgression(mode: 'uploading' | 'downloading' | 'archiving') {
        const status = {
            uploading: new BehaviorSubject<boolean>(true),
            progression: {},
        };
        this.openModal(status, mode);
        return status;
    }

    protected openModal(status: IUploadingStatus, mode: 'uploading' | 'downloading' | 'archiving') {
        this.dialog.open(UploadingDialogComponent, {
            data: {
                mode,
                status,
            }
        });
    }
}
