import { BaseModel } from "src/app/models/base.model";


export interface IDeadline {
    deadlineComment?: string;
    comment?: string;
}

export class Deadline extends BaseModel<IDeadline> implements IDeadline {

    deadlineComment?: string = undefined;
    comment?: string = undefined;

    constructor(data: Partial<Deadline> = {}) {
        super();
        this.patch(data);
    }
}