import * as _ from "lodash";
import { BaseModel } from "./base.model";
import { IUser } from "./users.model";

export enum GroupApprobationType {
    ONE_FOR_ALL = "one_for_all",
    INDIVIDUAL = "individual"
}

export interface IGroup {
    _id?: string;
    name: string;
    marque: string;
    userIds: string[];
    users?: IUser[];
    approbation_type?: GroupApprobationType;
}

export class Group extends BaseModel<IGroup> implements IGroup {
    name: string;
    marque: string;
    userIds: string[];
    users?: IUser[];

    customer?: string;
    approbation_type?: GroupApprobationType;

    constructor(data: Partial<IGroup>) {
        super();
        this.init(data);
    }
}