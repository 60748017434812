import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(protected router: Router, protected auth: AuthService ) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		if (this.auth.authenticated /*&& this.auth.hasCFSession()*/) {
			return true;
		} else {
			try {
				await this.auth.reAuthenticate();
				if(this.auth.user /*&& this.auth.hasCFSession()*/){
					return true;
				}else {
					this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
					return false;
				}
			} catch(err){
				this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
				return false;
			}
		}
	}
}