import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../services/alert.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {

    isLoading: boolean = false;

    constructor(
        protected alert: AlertService,
        protected route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params.notAuthenticated === true) {
                this.alert.error('ERRORS.NOT_AUTHENTICATED');
            }
        });
    }


    public changeFormLoading = (e: boolean): void => {
        this.isLoading = e;
    };
}
