import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { Marque } from '../models/marque.model';


@Injectable({
    providedIn: 'root',
})
export class MarqueService extends ResolverService<Marque> {
    path: string = 'marques';
    model: new (any: any) => Marque = Marque;

    constructor(
        protected feathers: KFeathers,
    ) { super(feathers); }

}
