import { BaseModel } from "./base.model";


export type NotifType = 'error' | 'proofscope' | 'briefing' | 'brief-files' | 'package' | 'download';


export interface INotificationOptions {
    'external'?: boolean;
    'reload-page'?: boolean;
    'go-to-url'?: boolean | { allowedURL: string[] };
}
export interface INotification {
    _id?:string,
    type: NotifType;
    id: string;
    url: string,
    userId?: string,
    message: string,
    data: any,
    date: Date,
    seen: boolean;
    opts?: INotificationOptions
}

export class Notification extends BaseModel<INotification> implements INotification {
    
    type: NotifType;
    id: string;
    url: string;
    userId?: string | undefined;
    message: string;
    data: any;
    date: Date;
    seen: boolean;
    opts?: INotificationOptions | undefined;

    constructor(data: Partial<INotification>) {
        super(data);
        this.init(data);
    }
}