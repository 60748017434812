<h2 mat-dialog-title class="">{{ ctx.title | translate }}</h2>

<mat-dialog-content>
    <p *ngIf="!ctx.rawText">{{ ctx.text | translate }}</p>
    <div *ngIf="ctx.rawText" [innerHTML]="ctx.text"></div>
</mat-dialog-content>

<mat-dialog-actions class="w-full flex justify-between">
    <button mat-flat-button *ngIf="!ctx.noConfirm" mat-dialog-close class="bg-tw-lightgrey flex-grow"><span class="">{{ 'CANCEL' | translate }}</span></button>
    <button mat-flat-button [mat-dialog-close]="true" class="bg-tw-primary text-tw-text-light flex-grow"><span class="">{{ ctx.valid | translate}}</span></button>
</mat-dialog-actions>