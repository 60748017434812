import { BaseModel } from "../../base.model";

export interface IL2PUBProject {
    UUID: string,
    NumDossier: string,
    IDDossier: number;
    IDClient: number;
    Etat: string;
    Affaire: string;
}


export class L2PUBProject extends BaseModel<IL2PUBProject> implements IL2PUBProject {
    UUID: string;
    NumDossier: string;
    IDDossier: number;
    IDClient: number;
    Etat: string;
    Affaire: string;

    constructor(data: Partial<IL2PUBProject> = {}) {
        super(data);
        this.init(data);
    }
}