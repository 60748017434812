import * as _ from 'lodash';
// feathers
import { ServiceMethods, Paginated, Params, Service } from '@feathersjs/feathers';
import { KFeathers } from './feathers.service';
import { Observable, BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { tap, first, map, catchError } from 'rxjs/operators';
import { KLocalResolverService } from './local-resolver.service';
import { BaseModel, Id } from 'src/app/models/base.model';
import { Helpers } from './helpers';
import { reverse, uniqBy } from 'lodash';

export interface IPopulate {
    from: string;
    route: string;
    key: string;
    result: string;
}


export type Query = { [x: string]: any; };
export type Data = { [x: string]: any; };

export interface IConfQueries {
    options: { $triggerEntityList: boolean, $needReloadList: boolean; $pushToEntityList?: boolean; $disablePending?: boolean; };
    params: Params;
}

export interface ResolverParams {
    // whenever reload the list of entities after a crud operation
    $needReloadList?: boolean;
    // whenever trigger the entity list observables upon a find
    $triggerEntityList?: boolean;
    // whenever push the new entity to the entity list upon a get request
    $pushToEntityList?: boolean;
    // disable
    $disablePending?: boolean;
    // callbacks for setting default queries upon find and reload list. 
    $callbacks?: {
        stateless?: boolean;
        find?: () => Query | any,
        reload?: () => Query | any,
        save?: (data: any) => any,
    };
    emit?: boolean;
    $page?: PageInfo;
    $populate?: IPopulate[];
}

export interface DefaultResolverConfig {
    // Trigger entity list observables
    triggerPolicy: boolean;
    // Reload entity list after operations
    reloadPolicy: boolean;
    // Default callbacks for setting default queries upon find and reload list.
    $callbacks?: {
        // true if the service is stateless and doesn't use callbacks
        stateless?: boolean;
        find?: () => Query | any,
        reload?: () => Query | any,
        save?: (data: any) => any,
    };
}

export interface ISyncRTOptions<T> {
    filter?: (item: T) => boolean,
    created: boolean | ((item: T) => Promise<void> | void),
    patched: boolean | ((item: T) => Promise<void> | void),
    removed: boolean | ((item: T) => Promise<void> | void),
}

export interface EmitCurrentOptions {
    emit?: boolean;
    localstorage?: boolean;
    sessionstorage?: boolean;
}

export interface PageInfo {
    total?: number,
    $limit?: number,
    $skip?: number;
}

export abstract class ResolverService<T extends BaseModel> {

    abstract path: string;
    abstract model: new (any: any) => T;

    protected _queriesFilterResolverService = ['$triggerEntityList', '$needReloadList', '$pushToEntityList', '$disablePending', '$callbacks'];
    protected _current: T | undefined = undefined;
    protected _entities: T[] = [];

    protected _current$: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(undefined);
    protected _entities$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    // protected queryManager?: IQueryManager;
    protected _page: PageInfo = {
        $limit: undefined,
        $skip: undefined,
        total: 0
    };

    protected _localResolver: KLocalResolverService<T> = new KLocalResolverService<T>(this);

    /** Configuration */
    public default: DefaultResolverConfig;

    /* ENTITY PENDING STATUS */
    protected _currentPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _entitiesPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _pendingTolerance = 50;
    get pending() {
        return {
            current: this._currentPending$.value,
            current$: this._currentPending$.asObservable(),
            entities: this._entitiesPending$.value,
            entities$: this._entitiesPending$.asObservable(),
        };
    }


    callbacks: {
        // true if the service is stateless and doesn't use callbacks
        stateless?: boolean,
        find?: () => Query | any,
        reload?: () => Query | any,
        save?: (data: any) => any,
    } = {};

    // queryUponReload: (() => ResolverParams) | undefined;

    /** GETTERS */
    get page(): PageInfo { return this._page; };
    get service(): Service<T> { return this.feathers.service(this.path); }
    get crud(): ServiceMethods<T> { return this.service; }
    get local(): KLocalResolverService<T> { return this._localResolver; }

    get current(): T | undefined {
        if (this._current) {
            return this._current;
        }
        let item = this.getStoredCurrent();
        if (item)
            return this.current = item;

        return undefined;
    }

    set current(current: T | undefined) {
        let c = undefined;
        if (current && Helpers.isModel(current)) {
            c = current;
        } else if (current) {
            c = this.builder(current || {});
        }
        this._current = c;
        this._current$.next(c);
    }

    get current$(): BehaviorSubject<T | undefined> {
        return this._current$;
    }

    get entities(): T[] {
        return this._entities;
    }

    set entities(list: T[]) {
        if (!list)
            list = [];
        this._entities = list;
        this._entities$.next(list);
    }

    get entities$(): Subject<T[]> {
        return this._entities$;
    }

    get isResolved(): boolean {
        return !_.isNil(this.current);
    }

    get isNew(): boolean {
        return !(this.current && this.current._id && Helpers.isId(this.current._id));
    }

    get isListEmpty(): boolean {
        return !this.entities || this.entities && this.entities.length === 0;
    }

    constructor(
        protected feathers: KFeathers
    ) {
        this.default = {
            triggerPolicy: true,  // Trigger entity list observables
            reloadPolicy: false,  // Reload entity list after operations
            $callbacks: {
                stateless: false,
                find: () => { return {}; },
                reload: () => { return {}; },
            }
        };
    }

    /**
     * Sync Real time 
     * Connect to service events with socket and update the entity list in real time. 
     * @param status 
     * @param options 
     */
    syncRT(status: 'enable' | 'disable', options: ISyncRTOptions<T> = { created: true, patched: true, removed: true }) {
        if (status === 'enable') {
            if (options.created) {
                this.feathers.on(`${this.path} created`, (item: T) => {
                    console.log('[SYNCRT] create', item);
                    if ((options.filter && options.filter(item)) || !options.filter) {
                        if (_.isFunction(options.created)) options.created(item);
                        this.local.rtCreated(item);
                    }
                });
            }
            if (options.patched) {
                this.feathers.on(`${this.path} patched`, (item: T) => {
                    console.log('[SYNCRT] patched', item);
                    if ((options.filter && options.filter(item)) || !options.filter) {
                        if (_.isFunction(options.patched)) options.patched(item);
                        this.local.rtPatched(item);
                    }
                });
                this.feathers.on(`${this.path} updated`, (item: T) => {
                    console.log('[SYNCRT] updated', item);
                    if ((options.filter && options.filter(item)) || !options.filter) {
                        if (_.isFunction(options.patched)) options.patched(item);
                        this.local.rtPatched(item);
                    }
                });
            }
            if (options.removed) {
                this.feathers.on(`${this.path} removed`, (item: T) => {
                    console.log('[SYNCRT] removed', item);
                    if ((options.filter && options.filter(item)) || !options.filter) {
                        if (_.isFunction(options.removed)) options.removed(item);
                        this.local.rtRemoved(item);
                    }
                });
            }
        } else {
            if (options.created)
                this.feathers.off(`${this.path} created`);
            if (options.patched) {
                this.feathers.off(`${this.path} patched`);
                this.feathers.off(`${this.path} updated`);
            }
            if (options.removed) {
                this.feathers.off(`${this.path} removed`);
            }
        }
    }

    /**
     * Resolve the current entity given by the url
     * @param id
     */
    resolve(id?: Id): Observable<T | undefined> {
        try {
            if (Helpers.isId(id)) {
                return this.getCurrent(id as Id);
            }
        } catch (e) {
            console.error(e);
        }
        // don't resolve if id is null
        this.current = undefined;
        return this.current$.pipe(first());
    }


    /**
    * Emit a new current entity
    * @param entity
    */
    emitCurrent(entity?: T | Partial<T>, options?: EmitCurrentOptions): void {
        entity = entity || this._current;
        let current;
        if (Helpers.isModel(entity)) {
            current = entity as T;
        } else {
            current = this.builder(entity);
        }

        if (options && options.localstorage) {
            localStorage.setItem(`${this.path}-current`, JSON.stringify(this.current));
        }
        if (options && options.sessionstorage) {
            sessionStorage.setItem(`${this.path}-current`, JSON.stringify(this.current));
        }

        if (options?.emit !== false) {
            this.current = current;
        } else {
            this._current = current;
        }

        console.log('[EMIT]', this.current);
    }

    /**
     * Get current entity given by the "resolve" method
     * @param id
     */
    getCurrent(id: Id, queries: Query = {}, params?: ResolverParams): Observable<T> {
        return from(this.get(id, queries, params)).pipe(tap((data: T) => this.emitCurrent(data, { emit: params?.emit }) ), map((data: T) => this.builder(data)));
    }


    /**
     * Post in database the current entity
     * @param queries
     */
    persistCurrent(data?: any, queries: Query = {}, params?: ResolverParams): Observable<T> {
        
        console.log('PERSIST', this.current);
        if (this.current) {
            if (data) {
                this.current.patch(data || {});
            }
            if (this.current._id) {
                return this.patchCurrent(data, queries, params);
            } else {
                return (this.create$(this.current, queries, params) as Observable<T>).pipe(tap((data: T) => this.emitCurrent(data)));
            }
        } else {
            throw '[Resolver] Can\'t create a null entity';
        }
    }


    /**
     * Update in database the current entity
     * @param queries
     */
    patchCurrent(data?: any, queries?: Query, params?: ResolverParams): Observable<T> {
        if (!this.current) {
            throw `[Resolver] Can't patch a null entity`;
        }
        if (this.isNew || !this.current._id) {
            throw `[Resolver] Can't patch a new entity`;
        }
        const _data = data || this.current;
        return (this.patch$(this.current._id, _data, queries || {}, params) as Observable<T>).pipe(tap((data: T) => this.emitCurrent(data)));
    }


    /**
     * Delete the current entity
     * @param queries
     */
    deleteCurrent(queries: Query = {}, params?: ResolverParams): Observable<any> {
        if (this.current) {
            if (this.isNew || !this.current._id) {
                throw `[Resolver] Can't delete a new entity`;
            }
            return this.delete$(this.current._id, queries, params)
                .pipe(tap((data: T) => this.resetCurrent()));
        } else {
            throw `[Resolver] Can't delete a null entity`;
        }
    }


    /**
     * Unset the current entity
     */
    resetCurrent(): void {
        this.current = undefined;
    }

    protected getStoredCurrent(): any | undefined {
        let item = sessionStorage.getItem(`${this.path}-current`);
        if (item) {
            return this.builder(JSON.parse(item));
        }
        item = localStorage.getItem(`${this.path}-current`);
        if (item) {
            return this.builder(JSON.parse(item));
        }
        return undefined;
    }

    unStoreCurrent() {
        localStorage.removeItem(`${this.path}-current`);
        sessionStorage.removeItem(`${this.path}-current`);
    }

    //#region Observables
    /**
     * Find entities by queries
     * @param queries
     */
    find$(queries: Query = {}, params?: ResolverParams): Observable<T[]> {
        return from(this.find(queries, params)).pipe(catchError(error => { console.error("ERR FIND"); this.defaultError(error); return throwError(error); }));
    }

    /**
   * Count entities using a query
   * @param queries
   */
    count$(queries: Query = {}, params?: ResolverParams): Observable<number> {
        return from(this.count$(queries, params)).pipe(catchError(error => { console.error("ERR COUNT"); this.defaultError(error); return throwError(error); }));
    }

    /**
     * Get an entity by its Id
     * @param id
     * @param queries
     */
    get$(id: Id, queries: Query = {}, params?: ResolverParams): Observable<T> {
        return from(this.get(id, queries, params)).pipe(catchError(error => { console.error("ERR GET"); this.defaultError(error); return throwError(error); }));
    }

    /**
     * Post one or many entities
     * @param data
     * @param queries
     */
    create$(data: T | T[], queries: Query = {}, params?: ResolverParams): Observable<T | T[]> {
        return from(this.create(data, queries, params)).pipe(catchError(error => {
            console.error("ERR CREATE"); this.defaultError(error); return throwError(error);
        }));
    }

    /**
     * Update one or many entities
     * @param id
     * @param data
     * @param queries
     */
    patch$(id: Id | null, data: Partial<T> & { [key: string]: any; }, queries: Query = {}, params?: ResolverParams): Observable<T | T[]> {
        return from(this.patch(id, data, queries, params)).pipe(catchError(error => {
            console.error("ERR PATCH"); this.defaultError(error); return throwError(error);
        }));
    }

    /**
     * Delete on or many entities
     * @param id
     * @param queries
     */
    delete$(id: Id | null, queries: Query = {}, params?: ResolverParams): Observable<any> {
        return from(this.delete(id, queries, params)).pipe(catchError(error => {
            console.error("ERR DELETE"); this.defaultError(error); return throwError(error);
        }));
    }

    /**
     * Deplicate entity, create a new one and return it
     * @param data
     * @param queries
     * @returns
     */
    duplicate$(data: T | T[], queries: Query = {}, params?: ResolverParams): Observable<T | T[]> {
        return from(this.duplicate(data, queries, params)).pipe(catchError(error => {
            console.error("ERR DUP"); this.defaultError(error); return throwError(error);
        }));
    }

    /**
     * Agnostic persistance method, update if .id is set, or create new if not
     * construct new model entity if the object is not already a model
     */
    persist$(entity: any, queries: Query = {}, params?: ResolverParams): Observable<T | T[]> {
        return from(this.persist(entity, queries, params)).pipe(catchError(error => {
            console.error("ERR PERSIST"); this.defaultError(error); return throwError(error);
        }));
    }
    //#endregion

    //#region PROMISES
    /**
     * Find entities by queries
     * @param queries
     */
    async find(queries: Query = {}, params: ResolverParams = {}): Promise<T[]> {
        if (!params.$callbacks?.stateless) {
            queries = _.assign(_.isFunction(this.callbacks.find) ? this.callbacks.find() : _.isObject(this.callbacks.find) ? this.callbacks.find : {}, queries);
        }
        let conf: IConfQueries = this.mapQueries(queries, params);
        //loading status
        let timeout;
        if (!conf.options.$disablePending) {
            timeout = setTimeout(() => this._entitiesPending$.next(true), this._pendingTolerance);
        }
        try {
            const row: Paginated<T> | T[] | T = await this.crud.find(conf.params);
            var paginate: Paginated<T>, datas: T[] = [];
            // if is Paginated
            if (row as Paginated<T> && (row as Paginated<T>).data && _.isArray((row as Paginated<T>).data)) {
                paginate = row as Paginated<T>;
                this._page = params.$page = {
                    $limit: paginate.limit,
                    $skip: paginate.skip,
                    total: paginate.total
                };
                datas = paginate.data;
            } else if (row as T[] && _.isArray(row)) {
                datas = row as T[];
            } else if (row as T) {
                datas = [row as T];
            }
            const data = datas.map(x => this.builder(x));

            if (conf.options.$triggerEntityList) {
                if (conf.options.$pushToEntityList) {
                    this.entities = reverse(uniqBy(reverse([...this.entities, ...data]), '_id'));
                } else {
                    this.entities = data;
                }
                // if (data.length > 0) {
                //     this.entities = data;
                // } else {
                //     this.entities = [];
                // }
            }
            return data;
        } catch (error) {
            this.defaultError(error);
            throw error;
        } finally {
            if (!conf.options.$disablePending && timeout) {
                clearTimeout(timeout);
                this._entitiesPending$.next(false);
            }
        }
    }

    /**
   * Count entities using a query
   * @param queries
   */
    async count(queries: Query = {}, params?: ResolverParams): Promise<number> {
        let conf: IConfQueries = this.mapQueries(queries, params);
        conf.params.query = _.merge(conf.params.query, { $limit: 0 });
        try {
            const paginated: Paginated<T> | T[] | T = await this.crud.find(conf.params);
            if (_.isArray(paginated))
                return (paginated as T[]).length;
            else
                return (paginated as Paginated<T>).total || 0;
        } catch (error) {
            this.defaultError(error);
            throw error;
        }
    }

    /**
     * Get an entity by its Id
     * @param id
     * @param queries
     */
    async get(id: Id, queries: Query = {}, params?: ResolverParams): Promise<T> {
        let conf: IConfQueries = this.mapQueries(queries, params);
        let timeout;
        if (!conf.options.$disablePending) {
            timeout = setTimeout(() => this._currentPending$.next(true), this._pendingTolerance);
        }
        try {
            const item: T = await this.crud.get(id, conf.params);
            const newModel = this.builder(item);
            if (params?.$pushToEntityList && this.entities.findIndex(x => x._id === newModel._id) === -1) {
                this.entities.push(newModel);
            }
            return newModel;
        } catch (error) {
            this.defaultError(error);
            throw error;
        } finally {
            if (!conf.options.$disablePending && timeout) {
                clearTimeout(timeout);
                this._currentPending$.next(false);
            }
        }
    }

    /**
     * Post one or many entities
     * @param data
     * @param queries
     */
    async create(data: T | T[] | Partial<T>, queries: Query = {}, params?: ResolverParams): Promise<T | T[]> {
        data = _.isArray(data) ? data.map(d => d.toJSON ? d.toJSON() : d) : (data.toJSON ? data.toJSON() : data);
        if (this.callbacks.save) data = await this.callbacks.save(data);
        let conf: IConfQueries = this.mapQueries(queries, params);
        let timeout;
        if (!conf.options.$disablePending) {
            timeout = setTimeout(() => this._currentPending$.next(true), this._pendingTolerance);
        }
        try {
            const item: T | T[] = await this.service.create(data, conf.params);
            if (conf.options.$needReloadList === true) {
                this.reloadEntities();
            }
            return _.isArray(item) ? item.map(x => this.builder(x)) : this.builder(item);
        } catch (error) {
            this.defaultError(error);
            throw error;
        } finally {
            if (!conf.options.$disablePending && timeout) {
                clearTimeout(timeout);
                this._currentPending$.next(false);
            }
        }
    }

    /**
     * Update one or many entities
     * @param id
     * @param data
     * @param queries
     */
    async patch(id: Id | null, data: Partial<T> & { [key: string]: any; }, queries: Query = {}, params?: ResolverParams): Promise<T | T[]> {
        data = data.toJSON ? data.toJSON() : data;
        if (this.callbacks.save) data = await this.callbacks.save(data);
        let conf: IConfQueries = this.mapQueries(queries, params);
        let timeout;
        if (!conf.options.$disablePending) {
            timeout = setTimeout(() => this._currentPending$.next(true), this._pendingTolerance);
        }
        try {
            const item: T | T[] = await this.crud.patch(id, data, conf.params);
            if (conf.options.$needReloadList === true) {
                this.reloadEntities();
            }
            return _.isArray(item) ? item.map(x => this.builder(x)) : this.builder(item);
        } catch (error) {
            this.defaultError(error);
            throw error;
        } finally {
            if (!conf.options.$disablePending && timeout) {
                clearTimeout(timeout);
                this._currentPending$.next(false);
            }
        }
    }

    /**
     * Delete on or many entities
     * @param id
     * @param queries
     */
    async delete(id: Id | null, queries: Query = {}, params?: ResolverParams): Promise<any> {
        let conf: IConfQueries = this.mapQueries(queries, params);
        let timeout;
        if (!conf.options.$disablePending) {
            timeout = setTimeout(() => this._currentPending$.next(true), this._pendingTolerance);
        }
        try {
            const item: T | T[] = await this.crud.remove(id, conf.params);
            if (conf.options.$needReloadList === true) {
                this.reloadEntities();
            }
            return _.isArray(item) ? item.map(x => this.builder(x)) : this.builder(item);
        } catch (error) {
            this.defaultError(error);
            throw error;
        } finally {
            if (!conf.options.$disablePending && timeout) {
                clearTimeout(timeout);
                this._currentPending$.next(false);
            }
        }
    }

    /**
     * Deplicate entity, create a new one and return it
     * @param data
     * @param queries
     * @returns
     */
    duplicate(data: T | T[], queries: Query = {}, params?: ResolverParams): Promise<T | T[]> {
        let clone = (_.isArray(data)) ? data.map(d => d.toJSON()) : data.toJSON();
        return this.create(clone, queries, params);
    }

    /**
     * Agnostic persistance method, update if .id is set, or create new if not
     * construct new model entity if the object is not already a model
     */
    persist(entity: any, queries: Query = {}, params?: ResolverParams): Promise<T | T[]> {
        if (Helpers.hasId(entity)) {
            return this.patch(Helpers.getId(entity), Helpers.toModel(entity, this.model), queries, params);
        } else {
            return this.create(Helpers.toModel(entity, this.model), queries, params);
        }
    }

    //#endregion

    /**
    *  Manually emit a new entities array 
    */
    emitEntities(entities: T[]): void {
        this.entities = entities;
    }

    /**
     * Unset entities list
     */
    resetEntities(): void {
        this.entities = [];
    }

    /**
     * Reload entities list
     */
    reloadEntities(): void {
        let queries = _.isFunction(this.callbacks.reload) ? this.callbacks.reload() : _.isObject(this.callbacks.reload) ? this.callbacks.reload : {};
        this.find$(queries).subscribe();
    }

    /**
     * Map queries and extract resolver configuration
     * @param params
     */
    protected mapQueries(query: Query, params: ResolverParams = {}): IConfQueries {
        if (params.$callbacks) {
            this.callbacks = {
                ...this.callbacks,
                ...params.$callbacks
            };
        } else if (this.default.$callbacks) {
            this.callbacks = {
                ...this.callbacks,
                ...this.default.$callbacks
            };
        }

        let response: IConfQueries = {
            params: _.merge(params, { query }, { query: query?.query || {} }, { query: { $limit: params?.$page?.$limit, $skip: params?.$page?.$skip } }, { query: { $populate: params.$populate || undefined } }),
            options: {
                $triggerEntityList: this.default.triggerPolicy,
                $needReloadList: this.default.reloadPolicy,
                $pushToEntityList: false,
                $disablePending: false,
            }
        };

        if (_.isObject(response.params)) {
            Object.keys(response.params).forEach((key: string) => {
                if (this._queriesFilterResolverService.includes(key)) {
                    (response.options as any)[key] = _.cloneDeep(response.params[key]);
                    delete response.params[key];
                }
            });
        }
        // console.log('[RESOLVER]', this.path, response);
        return response;
    }

    protected builder(data: any): T {
        return new this.model(data);
    }

    protected defaultError(error: any): void {
        console.error("[RESOLVER][ERR]", error);
    }
}
