import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";


export interface IImagesToRetouch {
    files: IUploadedFile[];
    comment?: string;
}

export class ImagesToRetouch extends BaseModel<IImagesToRetouch> implements IImagesToRetouch {

    files: IUploadedFile[];
    comment?: string = undefined;

    constructor(data: Partial<ImagesToRetouch> = {}) {
        super();
        this.patch(data);
    }
}