<div class="container">
    <mat-toolbar class="form-toolbar">
        <mat-toolbar-row class="nav">
            <button mat-fab *ngIf="valid$ | async" color="accent" type="button" class="toolbarButton" id="submit" (click)="submitForm()" matTooltip="{{'USER.UPDATE' | translate}}">
                <span class="icon-save mat-icon"></span>
                <!-- <span class="txt-btn">{{'USER.UPDATE' | translate}}</span> -->
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <app-password-form [(model)]="model" [(form)]="form" (valid$)="valid$"></app-password-form>
</div>