<div id="login" [ngClass]="[isLoading ? 'loading' : '']">
    <div class="container h-full container-custom">
        <div class="row justify-start items-center h-full">
            <div class="w-[60%]">
                <img id="cerf" src="/assets/img/cerf_diadem_resized.png" alt="Cerf stylisé" />
            </div>
            <div class="w-[40%]">
                <app-login-form (isFormLoading)="changeFormLoading($event)"></app-login-form>
            </div>
        </div>
    </div>
</div>