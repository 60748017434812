import { BaseModel } from "../../base.model";
import { ICustomProcess, IPrinter, ISupport } from "../../printer.model";
import { IPrinterConstraints, IReproSpec } from "./general.model";


export interface IPrinting {
    printer: IPrinter;
    support: ISupport;
    process: ICustomProcess;
    comm_printer: string;
    plan_technique: string;
    constraints: IPrinterConstraints;
    repro_spec: IReproSpec;
}


export class Printing extends BaseModel<IPrinting> implements IPrinting {
    printer: IPrinter;
    support: ISupport;
    process: ICustomProcess;
    comm_printer: string;
    plan_technique: string;
    constraints: IPrinterConstraints;
    repro_spec: IReproSpec;
    


    constructor(data: Partial<IPrinting> = {}) {
        super(data);
        this.init(data);
    }
}