import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IProgress } from './api.service';
import { KFeathers } from './api/feathers.service';
import { LoaderService } from './loader.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

export interface IPopulate {
    from: string;
    route: string;
    key: string;
    result: string;
}

export interface IPage {
    limit: number,
    skip: number,
    total: number;
}

@Injectable({ providedIn: 'root' })
export class LivecoAPIService<T = any> {
    constructor(protected feathers: KFeathers, protected loader: LoaderService, protected alert: AlertService) { }


    defaultErrCb(err: any) {
        let msg = err?.message || err?.contents?.message || 'SOMETHING_WRONG_HAPPENED';
        if (_.isString(err?.contents)) {
            msg = err?.contents;
        }
        this.alert.error(msg);
        // this.loader.loading = false;
    }

    defaultProgress: EventEmitter<IProgress> = new EventEmitter();


    find$(query: Record<string, any>, route: string, $populate?: IPopulate[]): Observable<{ result: any, page: IPage; }> {
        return new Observable<{ result: any, page: IPage; }>((observer) => {
            this.feathers.service(route).find({ query: { ...query, $populate } })
                .then((response: any) => {
                    let page: IPage = {} as any;
                    let result: any[] | any = [];
                    if (_.isString(response)) {
                        result = response;
                        page = {} as any;
                    } else if (_.isArray(response)) {
                        page = {
                            total: response.length,
                            limit: response.length,
                            skip: 0,
                        };
                        result = response || [];
                    }
                     else if(response.data && response.total) {
                        page = {
                            limit: response.limit,
                            skip: response.skip,
                            total: response.total
                        };
                        result = response.data || [];
                    }
                    else {
                        result = response;
                    }
                    observer.next({ result, page });
                    observer.complete();
                })
                .catch((err) => {
                    console.error('[LIVECO SERVICE] [FIND]', err);
                    observer.error(err);
                })
                // .finally(() => this.loader.loading = false);
        });
    }

    get$(id: string, route: string, $populate?: IPopulate[], query?: any): Observable<any> {
        return new Observable<any>((observer) => {
            this.feathers.service(route).get(id, { query: { ...query, $populate } })
                .then((value: any) => {
                    observer.next(value);
                    observer.complete();
                })
                .catch((err) => {
                    console.error('[LIVECO SERVICE] [GET]', err);
                    observer.error(err);
                })
                // .finally(() => this.loader.loading = false);
        });
    }

    create$(route: string, data: T): Observable<any> {
        return new Observable<any>((observer) => {
            this.feathers.service(route).create(data as any)
                .then((value: any) => {
                    observer.next(value);
                    observer.complete();
                })
                .catch((err) => {
                    console.error('[LIVECO SERVICE] [CREATE]', err);
                    observer.error(err);
                })
                // .finally(() => this.loader.loading = false);
        });
    }

    patch$(id: string, route: string, data: T): Observable<any> {
        return new Observable<any>((observer) => {
            data = (data as any).toJSON ? (data as any).toJSON() : data;
            this.feathers.service(route).patch(id, data as any)
                .then((value: any) => {
                    observer.next(value);
                    observer.complete();
                })
                .catch((err) => {
                    console.error('[LIVECO SERVICE] [PATCH]', err);
                    observer.error(err);
                })
                // .finally(() => this.loader.loading = false);
        });
    }

    delete$(id: string, route: string): Observable<any> {
        return new Observable<any>((observer) => {
            this.feathers.service(route).remove(id)
                .then((value: any) => {
                    observer.next(value);
                    observer.complete();
                })
                .catch((err) => {
                    console.error('[LIVECO SERVICE] [DELETE]', err);
                    observer.error(err);
                })
                // .finally(() => this.loader.loading = false);
        });
    }

    find(query: Record<string, any>, route: string, populate?: IPopulate[]) {
        return this.find$(query, route, populate).toPromise();
    }

    get(id: string, route: string, populate?: IPopulate[], query?: any) {
        return this.get$(id, route, populate, query).toPromise();
    }

    create(route: string, data: T) {
        return this.create$(route, data).toPromise();
    }

    patch(id: string, route: string, data: T) {
        return this.patch$(id, route, data).toPromise();
    }

    delete(id: string, route: string) {
        return this.delete$(id, route).toPromise();
    }
}
