import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;
// const api_defer = require('@modules/cf-api.js').api_defer;

export enum VIEWMODE {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view'
}

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements AfterContentInit, OnDestroy, OnInit {
    protected subscription: Subscription = new Subscription();

    protected page_lang: string;

    // Is either inputable or taken from param id; input override param
    private _id: string;
    @Input() set id(_id: string) {
        if (_id)
            this._id = _id;
    }
    get id() {
        return this._id;
    }

    @Input() type: string;

    protected _mode$: BehaviorSubject<VIEWMODE> = new BehaviorSubject<VIEWMODE>(VIEWMODE.VIEW);
    get mode$() {
        return this._mode$;
    }
    get mode() {
        return this._mode$.value;
    }
    @Input() set mode(mode: VIEWMODE) {
        this._mode$.next(mode);
    };


    get hasID() {
        return !(_.isNil(this.id) || _.isEmpty(this.id));
    }

    get viewing() {
        return this.mode === VIEWMODE.VIEW;
    }
    get creating() {
        return this.mode === VIEWMODE.CREATE;
    }
    get editing() {
        return this.mode === VIEWMODE.EDIT;
    }

    // MODEL
    // codeArticle: string = "";

    public get VIEWMODE() {
        return VIEWMODE;
    }

    get loading(): boolean {
        return this.s_loader.loading;
    }
    set loading(v: boolean) {
        this.s_loader.loading = v;
    }

    // get currentUserType(): string {
    //     return this.auth.getUserType();
    // };

    folder: string;

    // statusApprovers: string;
    // statusCurrentUser: string;
    // statusPr: string;

    // projectId: string;


    // permsSplitview: any;
    // approvalHistoryExist: number;
    // proofreading: any;
    isFullScreen: boolean = false;

    get currentUser() {
        return this.s_auth.user;
    }

    get currentUser$() {
        return this.s_auth.onAuthenticated;
    }

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected s_loader: LoaderService,
        protected s_auth: AuthService,
        protected translate: TranslateService
    ) {
        this.page_lang = this.translate.currentLang;
        // this.loaderService.loading = true;
        this.subscription.add(this.route.params.subscribe(async (params) => {
            if (params && params['id'] && !this.id) {
                //Retrieve Project ID
                this.id = params['id'];
            }
            //Retrieve PAGE TYPE
            this.type = this.route.snapshot.data && this.route.snapshot.data.type;
            //Retrieve PAGE MODE
            this.mode = this.route.snapshot.data && this.route.snapshot.data.mode;
        }));
    }

    ngOnInit() {
        this.subscription.add(this.translate.onLangChange.subscribe((event) => {
            console.log('Lang changed to:', event.lang);
        }));
    }

    async ngAfterContentInit() {
        await this.retrieveData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // ngAfterContentInit(): void {
    //     console.log('ngAfterViewChecked');
    // }

    async retrieveData() {
    }

    /**
     * NAV
     */

    debug(...args: any) {
        console.log(args);
    }
    dump(...args: any) {
        return JSON.stringify(args);
    }

    isAdmin() {
        if (this.currentUser && this.currentUser.permissions) {
            return this.currentUser.permissions.includes('ADMIN');
        } else {
            return false;
        }
    }

    isCppOrFab() {
        return this.isCPP() || this.isFab();
    }

    goTohome() {
        this.router.navigate(['/']);
    }

    isOperator() {
        if (this.currentUser && this.currentUser.attributes_name) {
            return this.currentUser.attributes_name.includes('OPERATOR');
        } else {
            return false;
        }
    }

    isEXE() {
        if (this.currentUser && this.currentUser.attributes_name) {
            return this.currentUser.attributes_name.includes('EXE');
        } else {
            return false;
        }
    }

    isCPP() {
        if (this.isAdmin()) {
            return true;
        }

        if (this.currentUser && this.currentUser.attributes_name) {
            return this.currentUser.attributes_name.includes('CPP');
        } else {
            return false;
        }
    }

    isFab() {
        if (this.currentUser && this.currentUser.attributes_name) {
            return this.currentUser.attributes_name.includes('FAB');
        } else {
            return false;
        }
    }

    isClient() {
        if (this.currentUser && this.currentUser.attributes_name) {
            return this.currentUser.attributes_name.includes('CLIENT');
        } else {
            return false;
        }
    }


    onServError() {
        console.error("serveur indisponible");
    }

    getKeys(obj: any) {
        return Object.keys(obj);
    }

    trackByItems(index: number, item: any) {
        return item;
    }

}
