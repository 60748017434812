import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";

export interface IPrintingMethod {
    printingMethods?: string;
    printerName?: string;
    printerSpecifications?: IUploadedFile;
    comment?: string;
}

export class PrintingMethod extends BaseModel<IPrintingMethod> implements IPrintingMethod {

    printingMethods?: string = undefined;
    printerName?: string = undefined;
    printerSpecifications?: IUploadedFile = undefined;
    comment?: string = undefined;

    constructor(data: Partial<PrintingMethod> = {}) {
        super();
        this.patch(data);
    }
}