export const USER_TYPE_EXE = 'OPERATOR';
export const USER_TYPE_HD = 'HD';
export const USER_TYPE_REPRO= 'REPRO';
export const USER_TYPE_CPP = 'CPP';
export const USER_TYPE_CLIENT = 'CLIENT';
export const USER_TYPE_OPERATOR = 'OPERATOR';
export const USER_TYPE_FAB = 'FAB';
export const USER_TYPE_ADMIN = 'ADMIN';


export const USER_TYPES : string[] = [
    USER_TYPE_EXE,
    USER_TYPE_CPP,
    USER_TYPE_CLIENT,
    USER_TYPE_FAB,
    USER_TYPE_OPERATOR
]

export const USER_TYPES_OPERATOR : string[] = [
    USER_TYPE_EXE,
    USER_TYPE_HD,
    USER_TYPE_REPRO,
    USER_TYPE_FAB
]