import { IMember, MemberStatus, Member } from "./projects/submodels/members.model";
import { IUser } from "./users.model";

export interface IProofreadingMember extends IMember {
    status: MemberStatus;
    status_date?: Date;
    decision_makerID: string | null;
    decision_maker?: Partial<IUser> | null;
}


export class ProofreadingMember extends Member implements IProofreadingMember {
    status: MemberStatus;
    status_date?: Date;
    decision_makerID: string | null;
    decision_maker?: Partial<IUser> | null;

    constructor(data: Partial<IProofreadingMember>) {
        super(data);
        this.init(data);
    }
}

