import { ProjectService } from "src/app/services/project.service";
import { FilePathDescriptor, ITextSubRequest } from "../request.model";
import { CorrectionRequest } from "./correction-request.model";
import * as _ from "lodash";
import { MasterProjectService } from "src/app/services/project-master.service";
import { RequestType } from "src/app/LIVECO.define";


export class TextCorrectionRequest extends CorrectionRequest {
    override type: RequestType = RequestType.TEXT_CORRECTION;
    public static FILES: FilePathDescriptor = [
        { key: 'text.file_txt' },
    ];

    text: ITextSubRequest = {
        type: 'production-texts',
        textsID: undefined,
        file_txt: undefined,
        comment: '',
    };

    override options: any = {
        ...this.options,
        rvf: {
            jobType: "execution",
            dateIncrement: 2
        }
    };


    constructor(data: Partial<TextCorrectionRequest>) {
        super(data);
        this.init(data);
    }

    override onProjectCreation(service: ProjectService, master_service: MasterProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                this.insertExeBrief(service);
                break;
            case "submit":
                break;
        }
        super.onProjectCreation(service, master_service, lifecycle);
    }

    private insertExeBrief(service: ProjectService) {
        if (this.text?.type === 'instruction') {
            // Set request comm customer, name and reset service
            _.set(service.current || {}, 'custom.exe.text_brief', true);
            _.set(service.current || {}, 'custom.exe.commentaire_cpp', this.text?.comment);
        }
        if (this.text.type === 'file-txt') {
            _.set(service.current || {}, 'custom.exe.text_fichetexteclient', true);
            _.set(service.current || {}, 'custom.exe.commentaire_cpp', 'Ficher texte fourni par le client. Voir "Requête EZD"');
        }
        if (this.text.type === 'production-texts') {
            _.set(service.current || {}, 'custom.exe.commentaire_cpp', 'Fiche texte remplie par le client directement, elle sera intégrée automatiquement au projet. Voir "Requête EZD"');
            if (this.text.textsID) {
                service.s_productionTexts.getCurrent(this.text.textsID).toPromise();
            }
        }
    }
}
