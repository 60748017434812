import { BaseModel } from "../../base.model";
import { Contact, IContact } from "../../customer-contact";
import { Customer, ICustomer } from "../../customer.model";
import { IL2PUBProject, L2PUBProject } from "./l2pub-project.model";
import { IUser, User } from "../../users.model";

export interface IGeneralInfo {
    cppID?: string;
    customer: ICustomer;
    custom_customer_code?: string;
    customerInvoice?: ICustomer;
    nombre_ref: number;
    id_operateur: string;
    marque: string;
    designation: string;
    contact: IContact;
    cpp: IUser;
    l2pub: IL2PUBProject;
    family: string;
    agency: string;
    // lang: string;
}

export class GeneralInfo extends BaseModel<IGeneralInfo> implements IGeneralInfo {
    protected __build: { [path: string]: new (data: any) => any; } = {
        'customer': Customer,
        'customerInvoice': Customer,
        'contact': Contact,
        'cpp': User,
        'l2pub': L2PUBProject,
    };
    cppID?: string;
    customer: Customer;
    custom_customer_code?: string;
    customerInvoice?: Customer;
    nombre_ref: number;
    id_operateur: string;
    marque: string;
    designation: string;
    contact: Contact;
    cpp: User;
    l2pub: L2PUBProject;
    family: string;
    agency: string;
    // lang: string;

    constructor(data: Partial<IGeneralInfo> = {}) {
        super(data);
        this.init(data);
    }
}