import { ProjectService } from "src/app/services/project.service";
import { IRelectureSubRequest, IRequest, Request, RequestStatus } from "../request.model";
import * as _ from "lodash";
import { FlowType } from "../../proofreading.model";
import { IUploadedFile } from "src/app/services/file.service";
import { MasterProjectService } from "src/app/services/project-master.service";
import { RequestType } from "src/app/LIVECO.define";

export interface IPDFRepositoryRequest extends IRequest {
    files?: IUploadedFile[];
}

export class PDFRepositoryRequest extends Request implements IPDFRepositoryRequest {
    override type: RequestType = RequestType.PDF_REPOSITORY;
    override flow: FlowType | null = FlowType.EZD_PDF_REPOSITORY;
    files?: IUploadedFile[];

    override relecture: IRelectureSubRequest = {
        proofreadingsID: [],
        members: []
    };

    override get isEditable() {
        return this.status !== RequestStatus.CLOSED && this.status !== RequestStatus.DELIVERED;
    }

    constructor(data: Partial<PDFRepositoryRequest>) {
        super(data);
        this.init(data);
    }

    onProjectCreation(service: ProjectService, master_service: MasterProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                this.insertCommCustomerInProject(service);
                this.insertRequestMemberInProjectMember(service);
                this.flagLimitedCreation(service);
                this.setServiceInProject(service);
                break;
            case "submit":
                break;
        }
        super.onProjectCreation(service, master_service, lifecycle);
    }

    onProjectEdition(service: ProjectService): void {
        this.flagLimitedCreation(service);
        super.onProjectEdition(service);
    }

    private flagLimitedCreation(service: ProjectService) {
        service.s_requests.setFlag("project-limited-creation", ['base'] /** select only mandatory fields */);
    }

    private insertRequestMemberInProjectMember(service: ProjectService) {
        // Set request member in project member
        _.set(service.current || {}, 'custom.members', this.relecture?.members);
        // flag the easydiadem service so member edit is disabled
        service.s_requests.setFlag("project-members-creation-locked", true);
    }

    private insertCommCustomerInProject(service: ProjectService) {
        _.set(service.current || {}, 'custom.other.commentaire_cpp', this?.finalization?.comment);
    }

    private setServiceInProject(service: ProjectService) {
        _.set(service.current || {}, 'custom.general.service_exe', false);
        _.set(service.current || {}, 'custom.general.service_repo', false);
        _.set(service.current || {}, 'custom.general.service_repro', false);
        _.set(service.current || {}, 'custom.general.service_hd', false);
        _.set(service.current || {}, 'custom.general.service_other', true);

        // Remove objects from other services
        _.set(service.current || {}, 'custom.exe', undefined);
        _.set(service.current || {}, 'custom.repro', undefined);
        _.set(service.current || {}, 'custom.pdf_repo', undefined);
        _.set(service.current || {}, 'custom.hd', undefined);
    }
}
