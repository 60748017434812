import * as _ from 'lodash';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    get classes(): any {
        return {
            success: this.options.success || false,
            error: this.options.error || false,
            'alert-text': true
        };
    }

    get options(): any {
        return this._options || {};
    }

    constructor(@Inject(MAT_SNACK_BAR_DATA) protected _options: any) { }

    ngOnInit(): void { }

    isMessage(): boolean {
        return !this.isSuccess() && !this.isError();
    }

    isSuccess(): boolean {
        return this.options.success === true;
    }

    isError(): boolean {
        return this.options.error === true;
    }

    inject() {
        return _.template(this.options.message)(this.options || {});
    }

}
