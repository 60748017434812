import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './can-deactivate.interface';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/dialog/confirm-dialog/confirm-dialog.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(protected dialog: MatDialog) { }

    async buildDialog() {
        let dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'WARNING',
                text: 'UNSAVED_CHANGES'
            }
        });
        return <boolean>await dialog.afterClosed().toPromise();
    }


    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> | Promise<boolean> {
        console.log('COMPONENT', component);
        return (component?.canDeactivate && component?.canDeactivate()) ? this.buildDialog() : true;
    }
}