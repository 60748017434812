import { BaseModel } from "src/app/models/base.model";

export interface IValidationCycle {
    validationCycleComment?: string;
    comment?: string;
}

export class ValidationCycle extends BaseModel<IValidationCycle> implements IValidationCycle {

    validationCycleComment?: string = undefined;
    comment?: string = undefined;

    constructor(data: Partial<ValidationCycle> = {}) {
        super();
        this.patch(data);
    }
}