<button class="item-menu no-bar flex flex-row items-center content-fit" mat-button [disabled]="!notifs.length" [matMenuTriggerFor]="notifList" (onMenuClose)="onOpen()">
    <mat-icon class="mx-2" [matBadge]="unseen.length" [matBadgeHidden]="!unseen.length" matBadgeColor="accent">notifications</mat-icon>
</button>
<mat-menu class="notif-container" #notifList="matMenu">
    <div class="actions">
        <h3 class="title">{{ 'NOTIFICATIONS.LIST' | translate }}</h3>
        <button mat-button (click)="deleteNotif()"><mat-icon>close</mat-icon></button>
    </div>
    <ng-container *ngFor="let notif of notifs">
        <a class="item-menu" (click)="goTo(notif)">
            <button class="flex justify-between" mat-menu-item [ngClass]="notif.type" [class.seen]="notif.seen" [matTooltipPosition]="'before'" [matTooltipShowDelay]="500" matTooltip="{{ getMessage(notif) }}">
                <span class="date">{{ formatDate( notif.date ) }}</span>
                <mat-icon>{{getIcon(notif)}}</mat-icon>
                <span>{{getMessage(notif)}}</span>
            </button>
        </a>
    </ng-container>
</mat-menu>