import * as _ from 'lodash';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';

import { USER_TYPE_CLIENT, USER_TYPE_CPP, USER_TYPE_EXE } from 'src/app/models/usersTypes.model';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/users.model';
import { LivecoAPIService } from 'src/app/services/liveco-api.service';
import { Marque } from 'src/app/models/marque.model';

// const api = require('@modules/cf-api.js').api;;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {

    @ViewChild("containerHeader") containerHeader: ElementRef;
    @ViewChild("easydiademHeader") easydiademHeader: ElementRef;

    get loading(): boolean { return this.loaderService.loading; };
    isCpp: boolean = false;
    isClient: boolean = false;
    isOperator: boolean = false;
    // typeUser: string;
    userTypesExe = USER_TYPE_EXE;
    userTypesCpp = USER_TYPE_CPP;
    userTypesClient = USER_TYPE_CLIENT;
    userFullName: string;
    u: User;
    urlLogoClient: string[] = [];

    get userEasydiadem() {
        return this.auth?.userBrand.some((brand: Marque) => brand?.flags?.easydiadem) || false;
    }


    constructor(
        protected router: Router,
        protected loaderService: LoaderService,
        protected auth: AuthService,
        protected liveco: LivecoAPIService
    ) { }

    ngOnInit(): void {
        this.auth.onAuthenticated.subscribe(async u => {
            if (u && u.attributes_name) {
                this.u = u;
                const url = await this.liveco.find({ user: this.u }, "/getClientLogo");
                this.urlLogoClient = url.result;
                this.userFullName = u.fullname;
                
                // this.typeUser = this.auth.getUserType();

                if (u.attributes_name.includes(USER_TYPE_CPP)) {
                    this.isCpp = true;
                }
                else if (u.attributes_name.includes(USER_TYPE_CLIENT)) {
                    this.isClient = true;
                }
            }
        });
    }

    ngAfterViewInit() {
        // this.recalculateEdHeaderWidth();
    }

    logout() {
        this.auth.logout();
    }

    goToEadyDiadem() {
        return '/easydiadem/dashboard';
    }

    easyDiademRoute() {
        return this.router.url.includes('/easydiadem/');
    }

    removeFilterDashboard() {
        localStorage.removeItem('liveco-filters-project-dashboard');
    }
}