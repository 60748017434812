import { BaseModel } from "src/app/models/base.model";

export interface IClientContact {
    clientContactComment?: string;
    comment?: string;
}

export class ClientContact extends BaseModel<IClientContact> implements IClientContact {

    clientContactComment?: string = undefined;
    comment?: string = undefined;

    constructor(data: Partial<ClientContact> = {}) {
        super();
        this.patch(data);
    }
}