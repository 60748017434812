import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";


export enum WorkSubject {
    UPDATE = 'update',
    UPDATE_MULTIPLE = 'update-multiple',
    NEW_REF = 'new-ref',
    NEW_MULTIPLE = 'new-multiple',
    LOGO_EXE = 'logo-exe',
    OTHER = 'other',
}

export enum WorkSubject_TC {
    EXE_ONLY = 'exe-only',
    EXE_AND_RETOUCH = 'exe-and-retouch',
    RETOUCH_ONLY = 'retouch-only',
    LOGO_EXE = 'logo-exe',
    FILE_PREPARATION = 'file-preparation',
    ISOLATION = 'isolation',
    OTHER = 'other',
}

export interface ICustomerBrief {
    subject: WorkSubject[];
    other_subject?: string;
    brief_url?: IUploadedFile;
    comment?: string;
}

export class CustomerBrief extends BaseModel<ICustomerBrief> implements ICustomerBrief {

    subject: WorkSubject[] = [WorkSubject.UPDATE];
    other_subject?: string = undefined;
    brief_url?: IUploadedFile = undefined;
    comment?: string = undefined;

    constructor(data: Partial<CustomerBrief> = {}) {
        super();
        this.init(data);
    }
}