import * as _ from "lodash";
import { ResolverService } from "./resolver.service";
import { BaseModel, Id } from "src/app/models/base.model";
import { Helpers } from "./helpers";
import { BehaviorSubject } from "rxjs";

export class KLocalResolverService<T extends BaseModel> {
    private resolver: ResolverService<T>;

    protected get entities(): T[] {
        return this.resolver.entities;
    }
    protected get current(): T | undefined {
        return this.resolver.current;
    }
    protected get current$(): BehaviorSubject<T | undefined> {
        return this.resolver.current$;
    }


    constructor(resolv: ResolverService<T>) {
        this.resolver = resolv;
    }

    resolve(id?: Id): T | undefined {
        if (id) {
            return this.entities.find(
                (entity: T) => Helpers.hasId(entity) && Helpers.getId(entity) === id
            );
        } else return undefined;
    }

    filter(value: string, listFields: string[]): T[] {
        return this.entities.filter((entity: T) => {
            let result = false;
            for (let field of listFields) {
                result = result || ((entity as any)[field] as string).toLowerCase().includes(value.toLowerCase());
                console.log(result, (entity as any)[field], value);
            }
            return result;
        });
    }

    /** Real Time Interface */
    rtCreated(item: T) {
        if (item && item._id) {
            let entity = this.resolve(item._id);
            if (!entity) {
                this.entities.push(new this.resolver.model(item));
            }
        }
    }

    rtPatched(item: T) {
        // check to patch the corresponding item in entity list
        let entity = this.resolve(item._id);
        if (entity) {
            entity.patch(item);
        }
        // check to patch current if patched item is current
        if (this.current && Helpers.getId(item) === this.current._id) {
            this.current.patch(item);
            this.current$.next(this.current);
        }
    }

    rtRemoved(item: T) {
        if (item && item._id) {
            let entity = this.resolve(item._id);
            if (entity) {
                let index = this.entities.findIndex((e) => Helpers.hasId(e) && Helpers.getId(e) === item._id);
                this.entities.splice(index, 1);
            }
        }
    }
}
