import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  parent: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  parent: string;
}


@Injectable({
  providedIn: 'root',
})

export class BrandCategoriesService {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
  }

  buildCategoriesTree(obj: { [key: string]: any; }, level: number, parent: string = ''): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;
      node.parent = parent;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildCategoriesTree(value, level + 1, node.item);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  buildCategories(nodes: TodoItemNode[]): { [key: string]: string[] } {
    const result:any = {};
    nodes.forEach(node => {
      if (node.children && node.children.length > 0) {
        const childrens : string[] = [];
        node.children.forEach(child => {
          childrens.push(child.item);
        });
        result[node.item] = childrens;
      } else {
        result[node.item] = [];
      }
    });
    return result;
  }

  insertSubCategory(parent: TodoItemNode, name: string, parentName: string) {
    if (parent.children) {
      parent.children.push({ item: name, parent: parentName } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  deleteSubCategory(name: string, parentName: string) {
    let parent = this.data.find(item => item.item === parentName);
    if (parent) {
      let child = parent.children.find(item => item.item === name);
      if (child) {
        parent.children = parent.children.filter(item => item.item !== name);
        this.dataChange.next(this.data);
      }
    }
  }

  updateCategory(node: TodoItemNode, name: string, children: TodoItemNode[] = []) {
    node.item = name;
    node.children = children;
    this.dataChange.next(this.data);
  }

  insertCategory(name: string) {
    if (this.data.length && this.data.some(item => item.item === name)) {
      throw new Error("BRAND.CATEGORIES.ALREADY_EXISTS");
    }
    this.data.push({ item: name, children: [], parent: '' } as TodoItemNode);
    this.dataChange.next(this.data);
  }

  deleteCategory(name: string) {
    let category = this.data.find(item => item.item === name);
    if (category) {
      const updatedData = this.data.filter(item => item.item !== name);
      this.dataChange.next(updatedData);
    }
  }


  isTopLevel(node: TodoItemNode) {
    return this.data.some((element: TodoItemNode) => element.item === node.item && node.parent === '');
  }
}
