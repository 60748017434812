import { ProofreadingService } from "../services/proofreading.service";
import { BaseModel, Id } from "./base.model";
import { IRequest } from "./easydiadem/request.model";
import { MemberStatus } from "./projects/submodels/members.model";
import { ProductionTexts } from "./production-texts.model";
import { IState, State } from "./states.model";
import { IProofreadingMember, ProofreadingMember } from "./proofreading-member.model";

export enum ApprovalAssessments {
    ACCEPT = 'accept',
    REJECT = 'reject'
}

export enum prefixFolderRevision {
    EXE = 'R',
    REPRO = 'V'
}

export interface ICSSState {
    color: string;
    bg: string;
}


export interface IViewer {
    file_url: string;
    url: string;
    version: number;
}


export interface IPRIntern {
    currentStateId: string;
    currentState: IState;
    viewer: IViewer;
    auto_control_value?: 'accept' | 'reject';
    cpp_control_value?: 'accept' | 'reject';
}

export enum FlowType {
    EZD_PDF_REPOSITORY = 'EZD_PDF_REPOSITORY',
    PDF_REPO = 'PDF_REPO',
    EZD_EXE = 'EZD_EXE',
    EXE = 'EXE',
    REPRO = 'REPRO',
}


export interface IProofReading {
    _id?: string;
    flow: FlowType;
    cppID: string;
    relecture: number;
    texts?: ProductionTexts;
    file_url: string;
    view_url: string;
    view_url_readonly: string;
    stateId: Id;
    state: IState;
    members: IProofreadingMember[];
    intern: IPRIntern | null;
    current_order: number;
    project: {
        ID: string,
        identifier: string,
    };
    flags?: {
        [key: string]: boolean;
    };
    request?: Partial<IRequest>;
}


export class Proofreading extends BaseModel implements IProofReading {
    protected __build: { [path: string]: new (data: any) => any; } = {
        state: State,
        members: ProofreadingMember,
        texts: ProductionTexts,
        'intern.currentState': State,
    };

    _id: string;
    flow: FlowType;
    relecture: number;
    stateId: string;
    state: State;
    members: ProofreadingMember[];
    texts?: ProductionTexts;
    cppID: string;
    intern: IPRIntern | null;
    file_url: string;
    view_url: string;
    view_url_readonly: string;
    current_order: number;
    project: {
        ID: string,
        identifier: string,
        populated?: any;
    };
    flags?: { [key: string]: boolean; } | undefined;
    request?: Partial<IRequest>;
    
    get main_member(): IProofreadingMember | undefined {
        return this.members.find(member => member.hasApproDocument);
    }

    get current_approval_number(): number {
        return this.members?.filter(member => member.status === MemberStatus.ACCEPT || member.status === MemberStatus.REJECT).length || 0;
    }

    get total_approval_number(): number {
        return this.members?.filter(member => member.hasApproDocument || member.hasApproInfo ).length || 0;
    }

    constructor(data: Partial<IProofReading> = {}) {
        super();
        this.init(data);
    }

    getPrefix() {
        return ProofreadingService.getPrefixRelecture(this.flow);
    }
}
