import { RequestType } from "src/app/LIVECO.define";
import { FilePathDescriptor, IPlanSubRequest } from "../request.model";
import { CorrectionRequest } from "./correction-request.model";

export class PlanCorrectionRequest extends CorrectionRequest {
    override type: RequestType = RequestType.PLAN_CORRECTION;
    public static FILES: FilePathDescriptor = [
        { key: 'plan.file_plan' },
    ];

    plan: IPlanSubRequest = {
        file_plan: undefined,
    };

    override options: any = {
        ...this.options,
        rvf: {
            jobType: "execution",
            dateIncrement: 2
        }
    };

    constructor(data: Partial<PlanCorrectionRequest>) {
        super(data);
        this.init(data);
    }
}
