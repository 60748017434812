import { Injectable } from "@angular/core";
import { Query, ResolverParams, ResolverService } from "./api/resolver.service";
import { KFeathers } from "./api/feathers.service";
import * as _ from "lodash";
import { Customer, ICustomer } from "../models/customer.model";


@Injectable({ providedIn: 'root' })
export class CustomerService extends ResolverService<Customer> {
    path: string = 'customers';
    model: new (any: any) => Customer = Customer;

    constructor(
        protected feather: KFeathers,
    ) {
        super(feather);
    }

    override find(queries?: Query, params?: ResolverParams): Promise<Customer[]> {
        return super.find(queries, params);
    }

    public hasOption(customer: ICustomer | undefined, optionName: string): boolean {
        if (!customer) {
            return false;
        }

        const customerObject: Customer = new Customer(customer);

        return customerObject.hasOption(optionName);
    }
}