import { BaseModel } from "src/app/models/base.model";
import { IUploadedFile } from "src/app/services/file.service";


export interface ITextSheet {
    selection: TextSheetType;
    archive?: string;
    file?: IUploadedFile,
    textsID?: string;
    comment?: string;
}

export enum TextSheetType {
    ARCHIVE = "archive",
    FILE = "file",
    ONLINE = "online"
}

export class TextSheet extends BaseModel<ITextSheet> implements ITextSheet {

    selection: TextSheetType = TextSheetType.ARCHIVE;
    archive?: string = undefined;
    file?: IUploadedFile = undefined;
    textsID?: string = undefined;
    comment?: string = undefined;

    constructor(data: Partial<TextSheet> = {}) {
        super();
        this.patch(data);
    }
}