import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { ProductionTexts } from '../models/production-texts.model';
import { defaultTexts } from '../LIVECO.define';
import { TVN } from '../models/tvn.model';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Injectable({
    providedIn: 'root',
})
export class ProductionTextsService extends ResolverService<ProductionTexts> {
    path: string = 'production-texts';
    model: new (any: any) => ProductionTexts = ProductionTexts;

    protected _display: BehaviorSubject<'legal' | 'marketing' | 'tvn'> = new BehaviorSubject<'legal' | 'marketing' | 'tvn'>('legal');
    get display() {
        return this._display.value;
    }
    set display(value: 'legal' | 'marketing' | 'tvn') {
        this._display.next(value);
    }

    constructor(
        protected feathers: KFeathers,
    ) { super(feathers); }

    addTexts(data: Partial<ProductionTexts>) {
        this.emitCurrent(new ProductionTexts({
            ...data,
            current: {
                langs: ['fr'],
                texts: defaultTexts,
                tvn: new TVN(),
            }
        }));
        return this.current;
    }

    prefill(data: Partial<ProductionTexts>) {
        return this.current?.patch(data);
    }
}
