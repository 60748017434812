import { ProjectService } from 'src/app/services/project.service';
import { IArchiveRequest, IArchiveSubRequest, IRelectureSubRequest, IRequest, Request } from '../request.model';
import * as _ from 'lodash';
import { IUploadedFile } from 'src/app/services/file.service';
import { MasterProjectService } from 'src/app/services/project-master.service';
import { RequestType } from 'src/app/LIVECO.define';

export interface IIsolationRequest extends IRequest {
  description: string;
  file?: IUploadedFile;
  archive: IArchiveSubRequest;
}


export class IsolationRequest extends Request implements IIsolationRequest, IArchiveRequest {
  override type: RequestType = RequestType.VISUALS_ISOLATION;

  description: string;
  // Used to retrieve the downloaded PDF on liveCo
  file?: IUploadedFile;

  override relecture: IRelectureSubRequest = {
    members: [],
  }

  archive: IArchiveSubRequest = {
    source_sub_path: '3. HD',
  };

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'hd',
      dateIncrement: 2
    },
  };

  constructor(data: Partial<IIsolationRequest>) {
    super(data);
    this.init(data);
  }

  onProjectCreation(service: ProjectService, master_service: MasterProjectService, lifecycle: 'init' | 'submit'): Promise<void> | void {
    switch (lifecycle) {
      case 'init':
        this.selectServiceInProject(service);
        this.flagLimitedCreation(service);
        this.insertCommCustomerInProject(service);
        this.insertRequestMemberInProjectMember(service);
        break;
      case 'submit':
        break;
    }
    // finish with the mother because it trigger service.refresh()
    super.onProjectCreation(service, master_service, lifecycle);
  }

  onProjectEdition(service: ProjectService): void {
    this.flagLimitedCreation(service);
    super.onProjectEdition(service);
  }

  private flagLimitedCreation(service: ProjectService) {
    service.s_requests.setFlag("project-limited-creation", ['base', 'riverflow']); 
  }

  private selectServiceInProject(service: ProjectService) {
    _.set(service.current || {}, 'custom.general.service_exe', false);
    _.set(service.current || {}, 'custom.general.service_repo', false);
    _.set(service.current || {}, 'custom.general.service_repro', false);
    _.set(service.current || {}, 'custom.general.service_hd', true);
    _.set(service.current || {}, 'custom.general.service_other', false);

    // Remove objects from other services
    _.set(service.current || {}, 'custom.exe', undefined);
    _.set(service.current || {}, 'custom.repro', undefined);
    _.set(service.current || {}, 'custom.pdf_repo', undefined);
    _.set(service.current || {}, 'custom.other', undefined);
}


  private insertCommCustomerInProject(service: ProjectService) {
    // Set request comm customer, name and reset service
    _.set(service.current || {}, 'custom.hd.commentaire_clienthd', this.description);
  }

  private insertRequestMemberInProjectMember(service: ProjectService) {
    // Set request member in project member
    _.set(service.current || {}, 'custom.members', this.relecture?.members);
    // flag the easydiadem service so member edit is disabled
    service.s_requests.setFlag("project-members-creation-locked", true);
}
}
