import { I } from "@angular/cdk/keycodes";
import { BaseModel } from "../base.model";


export interface IRiverflowJobType {
  name: string;
  groupIds: string[];
  relatedStateMachine: string;
  displayNames: any[];
  payload: any;
}

export class RiverflowJobType extends BaseModel<IRiverflowJobType> implements IRiverflowJobType {
  name: string;
  groupIds: string[];
  relatedStateMachine: string;
  displayNames: any[];
  payload: any;

  constructor(data: Partial<IRiverflowJobType> = {}) {
    super(data);
    this.init(data);
  }

}