import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { Request } from '../models/easydiadem/request.model';
import { RequestFactory } from '../models/easydiadem/request-factory.model';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root',
})
export class RequestsService extends ResolverService<Request> implements OnDestroy {
    path: string = 'easydiadem/requests';
    model: new (any: any) => Request = Request;

    protected subscription: Subscription = new Subscription();

    private flags = {};
    get FLAGS(): any {
        return this.flags;
    };

    constructor(
        protected feathers: KFeathers,
        protected translate: TranslateService
    ) {
        super(feathers);
    }


    resetFlag() {
        this.flags = {};
    }

    setFlag(key: string, value: any) {
        _.set(this.flags, key, value);
    }

    getFlag(key: string) {
        return _.get(this.flags, key);
    }

    protected override builder(data: any): Request {
        return RequestFactory.make(data);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}