import { Request, RequestTypeAgregation } from "./request.model";
import { BriefingRequest } from "./requests/briefing.model";
import { FileRedeliveryRequest } from "./requests/file-redelivery.model";
import { HeroImageRequest } from "./requests/hero-image.model";
import { IsolationRequest } from "./requests/isolation-request.model";
import { NewEpsonRequest } from "./requests/new-epson.model";
import { PackshotRequest } from "./requests/packshot.model";
import { PDFRepositoryRequest } from "./requests/pdf-repository-request.model";
import { PlanCorrectionRequest } from "./requests/plan-correction-request.model";
import { TextCorrectionRequest } from "./requests/text-correction-request.model";
import { TextPlanCorrectionRequest } from "./requests/text-plan-correction-request.model";
import { RequestType } from "src/app/LIVECO.define";

export class RequestFactory {

    public static make(r: Request | any): RequestTypeAgregation {
        switch (r.type) {

            case RequestType.PDF_REPOSITORY:
                return new PDFRepositoryRequest(r);

            case RequestType.VISUALS_ISOLATION:
                return new IsolationRequest(r);

            case RequestType.TEXT_CORRECTION:
                return new TextCorrectionRequest(r);

            case RequestType.PLAN_CORRECTION:
                return new PlanCorrectionRequest(r);

            case RequestType.TEXT_PLAN_CORRECTION:
                return new TextPlanCorrectionRequest(r);

            case RequestType.FILE_REDELIVERY:
                return new FileRedeliveryRequest(r);

            case RequestType.NEW_EPSON:
                return new NewEpsonRequest(r);

            case RequestType.PACKSHOT:
                return new PackshotRequest(r);

            case RequestType.HERO_IMAGE:
                return new HeroImageRequest(r);

            // case RequestType.NEW_ACCOUNT:
            //     return new NewAccountRequest(r);

            case RequestType.BRIEFING:
                return new BriefingRequest(r);

            // case RequestType.NONE:
            default:
                throw new Error("This request type don't exist " + r.type);
        }
    }
}



