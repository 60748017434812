import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-wrapper',
    templateUrl: './wrapper.component.html',
    styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
    public isPlatformBrowser: boolean = false;
    isFixed: boolean = false;
    routesFixed: string[] = ['/project/'];

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isPlatformBrowser = true;
            this.isFixed = this.routesFixed.some(route => this.router.url.includes(route));
    
        }
    }
    

   
}
