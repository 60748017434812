import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() { }

    public static async clipboardCopy(text: string) {
        await navigator.clipboard.writeText(text);
    }

    public static async clipboardPaste(): Promise<string> {
        return await navigator.clipboard.readText();
    }

    public static async sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    public static addBusinessDays(originalDate: moment.Moment, numDaysToAdd: number) {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = numDaysToAdd;

        const newDate = originalDate.clone();

        while (daysRemaining > 0) {
            newDate.add(1, 'days');
            if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
                daysRemaining--;
            }
        }

        return newDate;
    }
}
