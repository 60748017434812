<h2 mat-dialog-title>{{ ctx.title | translate }}</h2>
<mat-dialog-content>
    <p *ngIf="ctx.relecture" class="text-tw-primary">{{ 'INTERN.WARN_PROD.RELECTURE' | translate }}<strong>{{ ctx.relecture }}</strong></p>
    <p>{{ctx.text | translate }}</p>
    <div class="flex flex-col p-2">
        <p><strong>{{ 'TODO' | translate}} :</strong></p>
        <mat-checkbox *ngFor="let todo of incompleted" (change)="done(todo)">{{ 'INTERN.TODO.'+todo | translate }}</mat-checkbox>
    </div>
    <div class="flex flex-col p-2">
        <p><strong>{{ 'DONE' | translate}} :</strong></p>
        <mat-checkbox *ngFor="let todo of completed" [checked]="true" (change)="undone(todo)">{{ 'INTERN.TODO.'+todo | translate }}</mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-between">
    <button mat-flat-button mat-dialog-close class="flex-grow bg-tw-disabled">{{ 'CANCEL' | translate }}</button>
    <button mat-flat-button [disabled]="!allDone()" [mat-dialog-close]="true" class="flex-grow bg-tw-primary text-tw-text-light">{{ ctx.valid | translate}}</button>
</mat-dialog-actions>