import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';
import { IProgress } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {


    get select_mode() {
        if (this.progression?.value === 0) {
            return 'indeterminate';
        } else {
            return 'determinate';
        }
    }

    get loading(): boolean {
        return this._loading;
    }
    @Input() set loading(value: boolean) {
        this._loading = value;
        if (!this._loading) {
            this.progression = undefined;
        }
    };
    protected _progress?: EventEmitter<IProgress>;
    @Input() set progress(value: EventEmitter<IProgress> | undefined) {
        this._progress = value;
        if (this.progress) {
            this.sub.add(this.progress.subscribe(this.onProgress.bind(this)));
        }
    };
    get progress(): EventEmitter<IProgress> | undefined { return this._progress; }
    public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

    public _loading: boolean = false;
    public progression?: IProgress;
    protected sub: Subscription = new Subscription();


    @Input() config: NgxLoadingConfig = {
        animationType: ngxLoadingAnimationTypes.cubeGrid,
        backdropBorderRadius: '0',
        backdropBackgroundColour: 'rgba(255,255,255,0.1)',
        fullScreenBackdrop: false,
        primaryColour: '#a5d256',
        secondaryColour: '#643615',
        tertiaryColour: '#FFFF00'
    };

    constructor() { }

    ngOnInit(): void { }

    onProgress(progress: IProgress) {
        this.progression = progress;
    }


    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
