import { BaseModel } from "./base.model";

export interface IContact {
    _id: string;
    UUID: string;
    IDAdresse: number;
    IDClient: number;
    IDSociete: number;
    social_reason: string;
    contact: string;
    lastname: string;
    firstname: string;
    paymentTerms: string;
    numCompta: string;
    email: string;
    fonction: string;
    localisation: number;
    devise: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    postalCode: string;
    country: string;
    telephone: string;
    creation_date: string;
}

export class Contact extends BaseModel<IContact> implements IContact {
    UUID: string;
    IDAdresse: number;
    IDClient: number;
    IDSociete: number;
    social_reason: string;
    contact: string;
    lastname: string;
    firstname: string;
    paymentTerms: string;
    numCompta: string;
    email: string;
    fonction: string;
    localisation: number;
    devise: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    postalCode: string;
    country: string;
    telephone: string;
    creation_date: string;

    constructor(data: Partial<IContact>) {
        super(data);
        this.init(data);
    }
}