import { IRequest, Request } from '../request.model';
import { RequestType } from 'src/app/LIVECO.define';

interface IHotFolder {
  source : string,
  target : string,
  message :string
}

export interface INewEpsonRequest extends IRequest {
  quantity: number;
  epson_contact: string;
  hot_folder: IHotFolder;
}

export class NewEpsonRequest extends Request implements INewEpsonRequest {

  override type: RequestType = RequestType.NEW_EPSON;

  proofreading_id: string; // EXE or REPRO
  quantity: number;
  epson_contact: string;
  hot_folder: IHotFolder;
  // epson_contact: IContactSubRequest = {
  //   address: {
  //     city: '',
  //     cp: '',
  //     street: '',
  //   },
  //   email: '',
  //   firstname: '',
  //   lastname: '',
  //   tel: '',
  // };

  get isLinkable(): boolean {
    return false;
  }

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'epson',
      dateIncrement: 0
    },
  };

  constructor(data: Partial<NewEpsonRequest>) {
    super(data);
    this.init(data);
  }
}
