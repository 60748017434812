import * as _ from "lodash";
import { BaseModel } from "./base.model";
import { IUser } from "./users.model";
import { RequestType } from "../LIVECO.define";

export interface IMarque {
    _id?: string;
    label: string;
    IDSociete: number;
    archive: boolean;
    UUID: string;
    flags: any & { easydiadem?: boolean | RequestType[] } & { categories?: boolean | {} };
}

export class Marque extends BaseModel<IMarque> implements IMarque {
    label: string;
    IDSociete: number;
    archive: boolean;
    UUID: string;
    flags: any & { easydiadem?: boolean | RequestType[] } & { categories?: { [key: string]: string[] } } = {};

    constructor(data: Partial<IMarque>) {
        super(data);
        this.init(data);
    }
}