import { Injectable } from "@angular/core";
import { KFeathers } from "./api/feathers.service";
import { INotification, Notification } from "../models/notification.model";
import { LivecoAPIService } from "./liveco-api.service";
import { AuthService } from "./auth.service";
import { AlertService } from "./alert.service";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ResolverService } from "./api/resolver.service";


@Injectable({ providedIn: 'root' })
export class NotificationsService extends ResolverService<Notification> {

    path: string = 'notifications';
    model: new (any: any) => Notification = Notification;

    get user() {
        return this.auth.user;
    }

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected feathers: KFeathers,
        protected liveco: LivecoAPIService,
        protected auth: AuthService,
        protected alert: AlertService
    ) {
        super(feathers);
        const _id = '';
        this.feathers.on('notifications created', this.handle.bind(this));
    }

    async init() {
        await this.find({ $sort: { date: -1 } });
    }

    async handle(notif: INotification) {
        console.log('[NOTIFICATION]', notif);
        if (notif.userId !== this.auth.user?._id) {
            return;
        }

        // call notif alert
        this.alert.notif(notif);
        await this.execute(notif);

        // refresh user notif list
        if (notif.userId === this.auth.user?._id) {
            await this.init();
        }
    }

    async execute(notif: INotification) {
        const { url, opts } = notif;
        if (!opts)
            return;

        if (opts['reload-page']) {
            if (notif.url === this.router.url) {
                window.location.reload();
            }
        }

        if (opts['go-to-url']) {
            if (_.isBoolean(opts['go-to-url'])) {
                this.router.navigate([url]);
            } else if (_.isObject(opts['go-to-url']) && opts['go-to-url'].allowedURL) {
                if (opts['go-to-url'].allowedURL.some(url => this.router.url.includes(url))) {
                    this.router.navigate([url]);
                }
            }
        }
    }
}
