import * as _ from 'lodash';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { fr } from 'src/app/i18n/fr.lang';
import { en } from 'src/app/i18n/en.lang';
import { AuthService } from './services/auth.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [TranslateModule],
    providers: [TranslateService]
})
export class AppTranslateModule {

    constructor(private translate: TranslateService, protected s_auth: AuthService) {
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('fr');

        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        // set translation
        translate.setTranslation('fr', fr);
        translate.setTranslation('en', en);

        if (this.s_auth.user) {
            this.translate.use(this.s_auth.user.lang);
        }
    }

    static translateForRoot() {
        return TranslateModule.forRoot({
            // loader: {
            //   provide: TranslateLoader,
            //   // useFactory: (createTranslateLoader),
            //   deps: [HttpClient]
            // }
        });
    }

    static translateForChild() {
        return TranslateModule.forChild({
            // loader: {
            //   provide: TranslateLoader,
            //   useFactory: (createTranslateLoader),
            //   deps: [HttpClient]
            // }
        });
    }

}
